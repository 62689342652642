import cards from './cards.js'

export default class {
  static parentBadge(scopeCard) {
    // const scopeCard = cardCache[taskCard.shapeUp.scopeCardId]
    // if (!scopeCard) { // Might have been deleted
    //   return cards.badge({
    //     text: "Scope not found",
    //   })
    // }

    // const projectId = scopeCard.shapeUp.projectId
    // const project = projectCores[projectId]
    // if (!project) {
    //   return {
    //     // icon: $tpu.icons.task,
    //     text: "Project not found",
    //     refresh: 10, // Enable refresh because this could be temporary
    //   }
    // }

    return cards.badge({
      // text: `${project.name.truncate(10)}: ${scopeCard.name.truncate(20)}`,
      text: `Scope: ${scopeCard.name.truncate(20)}`,
    })
  }

  static parentDetailBadge(taskCard, projectCores, projectExtras, cardRegistry, scopeCard) {
    const projectId = scopeCard.shapeUp.projectId
    const project = projectCores[projectId]

    // Could happen if Scope was detached from its Project.
    const projectName = project ? project.name : null

    return cards.badge(
      {
        title: "Parent (Scope)",
        text: projectName ? `${projectName.truncate(20)} » ${scopeCard.name} »` : `${scopeCard.name} »`,
        // color: $tpu.styles.scopeColor(scopeCard, projectExtras[projectId]),
        callback: (t, opts) => {
          // this._badgeLinkActions(taskCard, projectCores, cardRegistry, scopeCard, t)
          t.showCard(scopeCard.id)
        },
      }
    )
  }

  static typeDetailBadge(taskCard, projectCores, cardRegistry, scopeCard) {
    return cards.badge(
      {
        title: "Type",
        text: "Task ↵",
        callback: (t) => {
          this._badgeLinkActions(taskCard, projectCores, cardRegistry, scopeCard, t)
        }
      }
    )
  }

  static _badgeLinkActions(taskCard, projectCores, cardRegistry, scopeCard, t) {
    const options = [
      // {
      //   text: 'Open Scope',
      //   callback: (t) => {
      //     t.showCard(scopeCard.id)
      //   }
      // },
      {
        text: 'Change Scope...',
        callback: (t) => {
          cards.attachToScope(taskCard, projectCores, cardRegistry, t)
        }
      },
      {
        text: 'Detach from Scope',
        callback: (t) => {
          $tpu.r.tasks.unlink(taskCard.id, scopeCard.id, t).then(() => {
            t.showCard(taskCard.id)
          })
        }
      }
    ]

    return t.popup({
      title: 'Actions',
      items: options
    });
  }

  // static _badgeNavigateAction(taskCard, projectCache, cardCache, scopeCard, t) {
  //   const options = [
  //     {
  //       text: 'Open Scope',
  //       callback: (t) => {
  //         t.showCard(scopeCard.id)
  //       }
  //     },
  //     {
  //       text: 'Change Scope...',
  //       callback: (t) => {
  //         cards.attachToScope(taskCard, projectCache, cardCache, t)
  //       }
  //     },
  //     {
  //       text: 'Detach from Scope',
  //       callback: (t) => {
  //         $tpu.r.tasks.unlink(taskCard.id, scopeCard.id, t).then(() => {
  //           t.showCard(taskCard.id)
  //         })
  //       }
  //     }
  //   ]

  //   return t.popup({
  //     title: 'Actions',
  //     items: options
  //   });
  // }
}
